<template>
  <div class="chart-component">
    <component v-bind:is="loaderComponent" v-if="showLoading"/>
    <div v-else-if="!hasData" class="empty-chart">
      <div class="empty-chart__container">
        <img
          :src="require('@/assets/images/line-chart.svg')"
          class="empty-chart__icon"
        />
      </div>
      <span class="empty-chart__text">{{ emptyText }}</span>
    </div>
    <highcharts v-else ref="highchart" v-bind:options="chartOptions"/>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import heatmap from 'highcharts/modules/heatmap';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import drilldown from 'highcharts/modules/drilldown';
import { mapState } from 'vuex';
import { Chart } from 'highcharts-vue';
import { chartColors, getDisplayableFormat } from '@/utils/helper';

export default {
  components: {
    highcharts: Chart,
    ChartLoader: () => import('@/components/loaders/Chart.vue'),
    PieChartLoader: () => import('@/components/loaders/PieChart.vue'),
  },
  props: {
    nullToZeroes: {
      type: Boolean,
      default: false,
    },
    translatable: {
      type: Boolean,
      default: false,
    },
    timeFormat: {
      type: Boolean,
      default: false,
    },
    chartId: {
      required: true,
      type: String,
    },
    dateFormat: {
      required: false,
    },
    dateYFormat: {
      required: false,
    },
    labels: {
      required: true,
      type: Array,
    },
    series: {
      required: true,
      type: Array,
    },
    type: {
      required: true,
      type: String,
      validator(value) {
        return ['bar', 'column', 'stackedColumn', 'line', 'pie', 'heatmap'].indexOf(value) !== -1;
      },
    },
    typeLoader: {
      required: false,
      type: String,
      default: 'chart',
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    isEmptyLine: {
      type: Boolean,
      default: false,
    },
    isEmptyStacked: {
      type: Boolean,
      default: false,
    },
    hasData: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({
      loader: state => state.dashboard.loader || {},
    }),
    showLoading() {
      return this.loader[this.chartId];
    },
    chartLabels() {
      if (this.isHeatmap) {
        return this.getHeatmapXAxis();
      }

      return this.labels.map(label => (
        this.dateFormat ? this.formatDate(label, this.dateFormat) : label
      ));
    },
    chartSeries() {
      if (this.isHeatmap) {
        return [{
          name: '',
          borderColor: '#EDEFF6',
          borderWidth: 1,
          data: this.getHeatmapSeries(),
          dataLabels: {
            enabled: false,
          },
        }];
      }

      if (this.isPie) {
        return [{
          name: this.$t('chart.series.total'),
          colorByPoint: true,
          data: this.getPieSeries(),
        }];
      }

      return this.series.map((series) => {
        const name = series.name ? this.getTranslatableString(series.name) : null;

        return {
          ...series,
          name,
        };
      });
    },
    chartType() {
      switch (this.type) {
        case 'stackedColumn':
          return 'column';
        default:
          return this.type;
      }
    },
    chartOptions() {
      const options = {
        title: {
          text: '',
        },
        yAxis: {
          title: {
            text: '',
          },
        },
        credits: {
          enabled: false,
        },
        chart: {
          type: this.chartType,
        },
        xAxis: {
          categories: this.chartLabels,
        },
        series: this.chartSeries,
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        exporting: {
          buttons: {
            contextButton: {
              enabled: false,
            },
          },
        },
      };

      // TODO move all custom configuration to a helper file
      if (this.isColumn || this.isLine) {
        options.xAxis.crosshair = true;
        options.tooltip = {
          shared: true,
        };
      }

      if (this.isBar) {
        options.plotOptions = {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        };
      }

      if (this.isStackedColumn) {
        options.yAxis.stackLabels = {
          enabled: true,
        };

        options.plotOptions = {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
            },
          },
        };
      }

      if (this.isLine) {
        options.plotOptions = {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        };
      }

      if (this.isPie) {
        options.chart.plotBackgroundColor = null;
        options.chart.plotBorderWidth = null;
        options.chart.plotShadow = false;
        options.tooltip = {
          pointFormat: '{series.name}: <b>{point.y}</b>',
        };
        options.plotOptions = {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
            showInLegend: false,
          },
        };
        options.drilldown = this.getPieDrillDown();
      }

      if (this.isHeatmap) {
        options.chart = {
          ...options.chart,
          plotBorderWidth: 0,
        };

        options.yAxis = {
          categories: this.getHeatmapYAxis(),
          title: null,
        };

        options.colorAxis = {
          min: 0,
          minColor: '#FFFFFF',
          maxColor: Highcharts.getOptions().colors[0],
        };

        options.legend = {
          align: 'right',
          layout: 'vertical',
          margin: 0,
          symbolHeight: 340,
          verticalAlign: 'top',
        };

        options.tooltip = {
          formatter() {
            return `${this.series.xAxis.categories[this.point.x]}<br/>${this.series.yAxis.categories[this.point.y]}<br/><b>${this.point.value}</b>`;
          },
        };
      }

      if (this.timeFormat) {
        const self = this;

        options.tooltip = {
          ...options.tooltip,
          shared: true,
          formatter() {
            return this.points.reduce((s, point) => {
              const formatted = self.getFormattedSeconds(point.y);
              return `${s}<br/><span style="fill: ${point.color}">● </span>${point.series.name}: ${formatted}`;
            }, `<b>${this.x}</b>`);
          },
        };
      }

      return options;
    },
    isBar() {
      return this.chartType === 'bar';
    },
    isColumn() {
      return this.type === 'column';
    },
    isStackedColumn() {
      return this.type === 'stackedColumn';
    },
    isLine() {
      return this.chartType === 'line';
    },
    isPie() {
      return this.chartType === 'pie';
    },
    isHeatmap() {
      return this.chartType === 'heatmap';
    },

    emptyText() {
      return this.$t('chart.empty');
    },
    loaderComponent() {
      let component;

      switch (this.typeLoader) {
        case 'piechart':
          component = 'pie-chart-loader';
          break;
        case 'chart':
        default:
          component = 'chart-loader';
          break;
      }

      return component;
    },
  },
  created() {
    Highcharts.setOptions({
      colors: chartColors,
    });

    exporting(Highcharts);
    offlineExporting(Highcharts);

    if (this.isPie) {
      drilldown(Highcharts);
    }

    if (this.isHeatmap) {
      heatmap(Highcharts);
    }
  },
  methods: {
    getNonNullString(value) {
      return value && value.length > 0 ? value : this.$t('chart.no-label');
    },
    getNullableValue(value) {
      if (this.nullToZeroes) {
        return value === null ? 0 : value;
      }

      return value;
    },
    getTranslatableString(value) {
      return this.translatable ? this.$t(value) : value;
    },
    getFormattedSeconds(value) {
      return this.$moment.duration(value, 'seconds')
        .format('h[h] m[m] s[s]');
    },
    getHeatmapXAxis() {
      return this.series.map(row => (
        this.dateFormat ? this.formatDate(row.name, this.dateFormat) : row.name
      ));
    },
    getHeatmapYAxis() {
      return !this.isEmpty > 0 ? this.series[0].data.map(row => (
        this.dateYFormat ? this.formatDate(row.label, this.dateYFormat) : row.label
      )) : [];
    },
    getHeatmapSeries() {
      const values = [];

      this.series.forEach((serie, xIndex) => {
        values.push(...serie.data.map((data, yIndex) => [
          xIndex,
          yIndex,
          this.getNullableValue(data.value),
        ]));
      });

      return values;
    },
    getPieSeries() {
      const values = [];

      this.series.forEach(({ name, y, children }, idx) => {
        values.push({
          name: this.getNonNullString(name),
          y,
          drilldown: children.length > 0 ? `child-${idx}` : null,
        });
      });

      return values;
    },
    getPieDrillDown() {
      const values = [];

      this.series.forEach(({ name, children }, idx) => {
        const childValues = [];

        children.forEach((child) => {
          childValues.push([
            this.getNonNullString(child.name),
            child.y,
          ]);
        });

        if (childValues.length > 0) {
          values.push({
            name: this.getNonNullString(name),
            id: `child-${idx}`,
            data: childValues,
          });
        }
      });

      return {
        series: values,
      };
    },
    formatDate(strDate, sourceFormat) {
      const isDaily = sourceFormat === 'DD';
      const isWeekly = sourceFormat === 'GGGG-WW';

      if (isDaily) {
        return strDate;
      }

      // TODO use startDate|endDate from range when displaying the first/last week respectively
      if (isWeekly) {
        const week = this.$moment(strDate, sourceFormat);
        const from = week.startOf('week')
          .format('DD MMM YYYY');
        const to = week.endOf('week')
          .format('DD MMM YYYY');
        return `${from} - ${to}`;
      }

      return this.$moment(strDate, sourceFormat)
        .format(getDisplayableFormat(sourceFormat));
    },
    triggerResize() {
      if (this.hasData) {
        if (this.$children[0].chart) {
          this.$children[0].chart.reflow();
        }
      }
    },
    exportAs(filename, type) {
      const { chart } = this.$refs.highchart;

      chart.exportChartLocal({
        filename,
        type,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/components/_data-chart.scss";
</style>
